import i18n, { defaultLocale } from "@/i18n/i18n";
import { Options, Vue } from "vue-class-component";

@Options({
  name: "locale-select-component",
})
export default class LocaleSelectComponent extends Vue {
  public locales = i18n?.global?.availableLocales || [];
  public current = i18n?.global?.locale?.value || defaultLocale;

  changeLocale(locale: string): void {
    if (this.locales.includes(locale)) {
      i18n.global.locale.value = locale;
      this.current = locale;

      if (locale !== defaultLocale) {
        this.$router.push({ params: { locale } });
        this.changeDocLang(locale);
      } else {
        this.$router.push({ params: { locale: "" } });
        this.changeDocLang(defaultLocale);
      }
    }
  }

  private changeDocLang(locale: string) {
    const html = document.documentElement;
    html.setAttribute("lang", locale);
  }
}
