<div class="home">
  <section class="section-1 w-full h-screen bg-gray-100 flex items-center" id="home">
    <div class="container">
      <div class="flex flex-col md:flex-row flex items-center">
        <div class="w-full md:w-1/2 px-10">
          <h1 class="text-3xl md:text-5xl mb-10" v-html="$t('section_1.title')"></h1>
          <p class="text-lg max-w-lg">{{ $t('section_1.description') }}</p>
        </div>

        <div class="w-full md:w-1/2 px-10 pt-14 md:pt-0">
          <img src="/assets/images/section-1.webp">
        </div>
      </div>
    </div>
    <svg id="wave" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
      preserveAspectRatio="none" class="svg">
      <path
        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z">
      </path>
    </svg>
  </section>

  <section class="section-2 w-full py-24" id="about">
    <div class="container">
      <div class="section-title flex flex-col items-center text-center md:px-0 px-10">
        <div class="icon rounded-full bg-gray-300 w-20 h-20 flex items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <h6 class="primary mt-4 font-semibold">{{ $t('section_2.name') }}</h6>
        <h3 class="title text-4xl mt-4 font-bold">{{ $t('section_2.title') }}</h3>
        <p class="text-lg	mt-4 max-w-prose	">{{ $t('section_2.description') }}</p>
      </div>

      <div class="card mt-20 rounded-xl">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-10">
          <div class="item m-14 flex flex-col items-center text-center">
            <div class="icon rounded-full shadow-lg w-20 h-20 flex items-center justify-center primary">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
              </svg>
            </div>
            <h5 class="text-2xl mt-6 font-semibold">{{ $t('section_2.card_1.title') }}</h5>
            <p class="text-lg	mt-6">{{ $t('section_2.card_1.description') }}</p>
          </div>
          <div class="item m-14 flex flex-col items-center text-center">
            <div class="icon rounded-full shadow-lg w-20 h-20 flex items-center justify-center primary">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
              </svg>
            </div>
            <h5 class="text-2xl mt-6 font-semibold">{{ $t('section_2.card_2.title') }}</h5>
            <p class="text-lg	mt-6">{{ $t('section_2.card_2.description') }}</p>
          </div>
          <div class="item m-14 flex flex-col items-center text-center">
            <div class="icon rounded-full shadow-lg w-20 h-20 flex items-center justify-center primary">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </div>
            <h5 class="text-2xl mt-6 font-semibold">{{ $t('section_2.card_3.title') }}</h5>
            <p class="text-lg	mt-6">{{ $t('section_2.card_3.description') }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section-3 w-full py-24" id="services">
    <div class="container">
      <div class="section-title flex flex-col items-center text-center md:px-0 px-10">
        <div class="icon rounded-full bg-gray-300 w-20 h-20 flex items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
        </div>
        <h6 class="primary mt-4 font-semibold">{{ $t('section_3.name') }}</h6>
        <h3 class="title text-4xl mt-4 font-bold">{{ $t('section_3.title') }}</h3>
        <p class="text-lg	mt-4 max-w-prose">{{ $t('section_3.description') }}</p>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-10 mt-20">
        <div class="card p-14 flex flex-col items-left text-left">
          <div class="icon w-20 h-20 flex items-center justify-center primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
            </svg>
          </div>
          <h5 class="text-2xl mt-6 font-semibold">{{ $t('section_3.card_1.title') }}</h5>
          <p class="text-lg	mt-6">{{ $t('section_3.card_1.description') }}</p>
        </div>
        <div class="card p-14 flex flex-col items-left text-left">
          <div class="icon w-20 h-20 flex items-center justify-center primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
            </svg>
          </div>
          <h5 class="text-2xl mt-6 font-semibold">{{ $t('section_3.card_2.title') }}</h5>
          <p class="text-lg	mt-6">{{ $t('section_3.card_2.description') }}</p>
        </div>
        <div class="card p-14 flex flex-col items-left text-left">
          <div class="icon w-20 h-20 flex items-center justify-center primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
            </svg>
          </div>
          <h5 class="text-2xl mt-6 font-semibold">{{ $t('section_3.card_3.title') }}</h5>
          <p class="text-lg	mt-6">{{ $t('section_3.card_3.description') }}</p>
        </div>
        <div class="card p-14 flex flex-col items-left text-left">
          <div class="icon w-20 h-20 flex items-center justify-center primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
            </svg>
          </div>
          <h5 class="text-2xl mt-6 font-semibold">{{ $t('section_3.card_4.title') }}</h5>
          <p class="text-lg	mt-6">{{ $t('section_3.card_4.description') }}</p>
        </div>
        <div class="card p-14 flex flex-col items-left text-left">
          <div class="icon w-20 h-20 flex items-center justify-center primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
          </div>
          <h5 class="text-2xl mt-6 font-semibold">{{ $t('section_3.card_5.title') }}</h5>
          <p class="text-lg	mt-6">{{ $t('section_3.card_5.description') }}</p>
        </div>
        <div class="card p-14 flex flex-col items-left text-left">
          <div class="icon w-20 h-20 flex items-center justify-center primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
          <h5 class="text-2xl mt-6 font-semibold">{{ $t('section_3.card_6.title') }}</h5>
          <p class="text-lg	mt-6">{{ $t('section_3.card_6.description') }}</p>
        </div>
      </div>
    </div>
  </section>

  <section class="section-4 w-full py-24">
    <div class="container">
      <div class="flex flex-col md:flex-row-reverse flex items-center">
        <div class="w-full md:w-1/2 text-center md:text-left px-10 md:px-0">
          <h3 class="text-4xl mb-4">{{ $t('section_4.title') }}</h3>
          <p class="text-lg	mb-14">{{ $t('section_4.description') }}</p>

          <a class="white-buttom uppercase font-semibold" href="/">{{ $t('contact_us') }}</a>
        </div>

        <div class="w-full md:w-1/2 flex items-center justify-center md:pt-0 pt-14 px-10 md:px-0">
          <img src="/assets/images/section-1.webp">
        </div>
      </div>
    </div>
  </section>

  <section class="section-5 w-full py-24" id="features">
    <div class="container">
      <div class="section-title flex flex-col items-center text-center md:px-0 px-10">
        <div class="icon rounded-full bg-gray-300 w-20 h-20 flex items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
          </svg>
        </div>
        <h6 class="primary mt-4 font-semibold">{{ $t('section_5.name') }}</h6>
        <h3 class="title text-4xl mt-4 font-bold">{{ $t('section_5.title') }}</h3>
        <p class="text-lg mt-4 max-w-prose">{{ $t('section_5.description') }}</p>
      </div>

      <div class="flex flex-col md:flex-row flex items-center mt-20">
        <div class="w-full md:w-1/2 px-10">
          <div class="icon w-20 h-20 flex items-center justify-center primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
            </svg>
          </div>
          <h5 class="text-2xl mt-6 font-semibold">{{ $t('section_5.feature_1.title') }}</h5>
          <p class="text-lg mt-6">{{ $t('section_5.feature_1.description') }}</p>
        </div>

        <div class="w-full md:w-1/2 flex items-center justify-center md:pt-0 pt-14 px-10">
          <img src="/assets/images/feature_1.webp">
        </div>
      </div>
      <div class="flex flex-col md:flex-row-reverse flex items-center mt-20">
        <div class="w-full md:w-1/2 px-10">
          <div class="icon w-20 h-20 flex items-center justify-center primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
            </svg>
          </div>
          <h5 class="text-2xl mt-6 font-semibold">{{ $t('section_5.feature_2.title') }}</h5>
          <p class="text-lg mt-6">{{ $t('section_5.feature_2.description') }}</p>
        </div>

        <div class="w-full md:w-1/2 flex items-center justify-center px-10 md:pt-0 pt-14">
          <img src="/assets/images/feature_2.webp">
        </div>
      </div>
      <div class="flex flex-col md:flex-row flex items-center mt-20">
        <div class="w-full md:w-1/2 px-10">
          <div class="icon w-20 h-20 flex items-center justify-center primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
            </svg>
          </div>
          <h5 class="text-2xl mt-6 font-semibold">{{ $t('section_5.feature_3.title') }}</h5>
          <p class="text-lg mt-6">{{ $t('section_5.feature_3.description') }}</p>
        </div>

        <div class="w-full md:w-1/2 flex items-center justify-center px-10 md:pt-0 pt-14">
          <img src="/assets/images/feature_3.webp">
        </div>
      </div>
    </div>
  </section>

  <section class="section-6 w-full py-24" v-if="false">
    <div class="container">
      <div class="section-title flex flex-col items-center text-center md:px-0 px-10">
        <div class="icon rounded-full bg-gray-300 w-20 h-20 flex items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <h6 class="primary mt-4 font-semibold">TESTIMONIAL</h6>
        <h3 class="title text-4xl mt-4 font-bold">Love By People</h3>
        <p class="mt-4">It is a long established fact that a reader will be distracted by the readable content of a page
          when looking at its layout.</p>
      </div>
    </div>
  </section>

  <section class="section-9 w-full py-0 md:py-24" id="contact">
    <div class="container">
      <div class="section-title flex flex-col items-center text-center md:px-0 px-10">
        <div class="icon rounded-full bg-gray-300 w-20 h-20 flex items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
        </div>
        <h6 class="primary mt-4 font-semibold">{{ $t('section_9.name') }}</h6>
        <h3 class="title text-4xl mt-4 font-bold">{{ $t('section_9.title') }}</h3>
        <p class="text-lg mt-4 max-w-prose">{{ $t('section_9.description') }}</p>
      </div>

      <div class="card mt-20 md:rounded-xl">
        <div class="flex flex-col md:flex-row p-10">
          <div class="w-full md:w-1/2 px-10">
            <div class="logo my-4">
              <a href="/" class="flex">
                <img class="max-h-10" src="/assets/logo.svg" alt="logo">
              </a>
            </div>
            <p class="mb-4">{{ $t('section_9.email') }} : hello@teknivox.com</p>
            <p class="mb-4">{{ $t('section_9.phone') }} : +1 (922) 789 7069 5656</p>
            <p class="mb-4">Del Valle 932, Ciudad Empresarial, Santiago, Chile.</p>

            <p class="mb-14">{{ $t('section_9.text_info') }}</p>
          </div>

          <div class="w-full md:w-1/2 px-10" v-if="!emailSent">
            <div class="form-group mt-3">
              <input name="name" id="name" type="text" class="form-control"
                :placeholder="$t('section_9.placeholder_name')" v-model="data.name">
            </div>
            <div class="form-group mt-3">
              <input name="email" id="email" type="text" class="form-control"
                :placeholder="$t('section_9.placeholder_email')" v-model="data.email">
            </div>
            <div class="form-group mt-3" v-if="false">
              <input name="subject" id="subject" type="text" class="form-control"
                :placeholder="$t('section_9.placeholder_subject')" v-model="data.subject">
            </div>
            <div class="form-group mt-3 mb-3" :class="{ 'mb-3': errorEmail, 'mb-6': !errorEmail }">
              <textarea name="message" id="message" rows="4" class="form-control"
                :placeholder="$t('section_9.placeholder_message')" v-model="data.message"></textarea>
            </div>
            <p class="mb-6 font-semibold italic" v-if="errorEmail">{{ $t(errorMsg) }}</p>
            <button class="tkv-buttom uppercase font-semibold clickable flex items-center" @click="sendEmail()"
              :disabled="sendingEmail">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24" v-if="sendingEmail">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
              </svg>

              {{ $t('contact_us') }}
            </button>
          </div>

          <div class="w-full md:w-1/2 px-10 flex flex-col items-center justify-center"  v-if="emailSent">
            <div class="icon rounded-full bg-gray-300 w-20 h-20 flex items-center justify-center mb-6">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <h3 class="text-center text-3xl">{{ $t('section_9.email_sent') }}</h3>
            <h6 class="primary mt-4 font-semibold">TEKNIVOX</h6>
          </div>
        </div>
      </div>
    </div>
  </section>

  <locale-select></locale-select>
</div>