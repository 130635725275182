
import { createI18n } from "vue-i18n";

// languages
// @ts-ignore
import en from "@/assets/i18n/en.json";
// @ts-ignore
import es from "@/assets/i18n/es.json";

export const defaultLocale = "es";

const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  globalInjection: true,
  messages: {
    en,
    es,
  },
});

export default i18n;