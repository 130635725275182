import { Options, Vue } from "vue-class-component";
import store from "@/store/store";

@Options({
  name: "sidebar-toggle",
})
export default class SidebarToggleComponent extends Vue {
  get isSidebarOpen(): boolean  {
    return store.getters.sideBar
  }

  toggle(): void {
    store.commit("toggleSideBar");
  }
}
