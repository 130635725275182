<div id="app">
	<div class="page-layout h-full flex flex-col bg-gray-100">
		<header-component />

		<main class="flex-1">
			<router-view />
		</main>

		<footer-component />
	</div>
</div>