
import { Options, Vue } from 'vue-class-component';
import LayoutComponent from './components/layout/layout.component.vue';

@Options({
  components: {
    LayoutComponent,
  },
})
export default class App extends Vue {}
