import { Options, Vue } from "vue-class-component";
import store from "@/store/store";

@Options({
  name: "sidebar-component",
})
export default class SidebarComponent extends Vue {
  get isOpen(): boolean {
    return store.getters.sideBar;
  }

  toggle(): void {
    store.commit("toggleSideBar");
  }
}
