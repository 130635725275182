import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/index/index.component.vue";
import i18n, { defaultLocale } from "@/i18n/i18n";

function availableLocales(): string {
  const locales = i18n?.global?.availableLocales || [];
  let reg = '';

  locales.forEach((locale: string, index: number) => {
    reg = `${reg}${locale}${index != (locales.length - 1)? "|" : ""}`;
  })

  return `(${reg})`;
}

const routes = [
  {
    path: `/:locale${availableLocales()}?`,
    name: "Home",
    component: Home,
    beforeEnter(to: any, from: any, next: any) {
      const locales = i18n?.global?.availableLocales || [];
      const locale = to.params.locale;
      let fromNavigator = false;

      if (locales.includes(locale)) {
        i18n.global.locale.value = locale;
      } else {
        const navigatorLanguage = navigator.language || "";
        const lang = navigatorLanguage.split("-")[0];

        if (locales.includes(lang) && defaultLocale !== lang) {
          i18n.global.locale.value = lang;
          fromNavigator = true;
        }
      }

      if (fromNavigator) {
        next(`/${i18n.global.locale.value}`);
      } else {
        next();
      }
    },
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;