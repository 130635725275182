import { Options, Vue } from "vue-class-component";
import HeaderComponent from "./header/header.component.vue";
import FooterComponent from "./footer/footer.component.vue";

@Options({
  name: "layout-component",
  components: {
    "header-component": HeaderComponent,
    "footer-component": FooterComponent,
  },
})
export default class LayoutComponent extends Vue {}
