import { createApp } from 'vue';
import App from './App.vue';

import scrollto from 'vue-scrollto';

import i18n from './i18n/i18n';
import router from './router/router';
import store from './store/store';

import './style/style.scss';

// globals
export const EMAIL_CREDENTIALS = {
  user_id: "user_eBak22BfnVg5o4a5InwnX",
  service_id: "service_qrb5j15",
  template_id: "template_33ni5gk",
};

// app
const app = createApp(App);

app.use(scrollto);
app.use(router);
app.use(store);
app.use(i18n);
app.mount('#app');