import { Options, Vue } from "vue-class-component";
import SidebarComponent from "../sidebar/sidebar.component.vue";
import SidebarToggleComponent from "../sidebar-toggle/sidebar-toggle.component.vue";
import scroller from "vue-scrollto/vue-scrollto";
import store from "@/store/store";

@Options({
  name: "header-component",
  components: {
    sidebar: SidebarComponent,
    "sidebar-toggle": SidebarToggleComponent,
  },
})
export default class HeaderComponent extends Vue {
  public fixedMenu = false;
  public currentSection = "home";

  mounted(): void {
    window.addEventListener("scroll", this.onScroll, true);
    this.onScroll();
  }

  beforeDestroy(): void {
    window.removeEventListener("scroll", this.onScroll, true);
  }

  onScroll(): void {
    const position = window.scrollY;
    const home: number = document.getElementById("home")?.offsetTop || 0;
    const about: number = document.getElementById("about")?.offsetTop || 0;
    const services: number =
      document.getElementById("services")?.offsetTop || 0;
    const features: number =
      document.getElementById("features")?.offsetTop || 0;
    const contact: number = document.getElementById("contact")?.offsetTop || 0;

    if (position >= home && position < about) {
      this.currentSection = "home";
    } else if (position >= about && position < services) {
      this.currentSection = "about";
    } else if (position >= services && position < features) {
      this.currentSection = "services";
    } else if (position >= features && position < contact) {
      this.currentSection = "features";
    } else {
      this.currentSection = "contact";
    }

    this.fixedMenu = position > 80;
  }

  scrollTo(el: string): void {
    scroller.scrollTo(el);
    store.commit("toggleSideBar");
  }
}
