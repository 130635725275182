import { Options, Vue } from "vue-class-component";
import emailjs from "emailjs-com";
import { EMAIL_CREDENTIALS } from "@/main";
import LocaleSelectComponent from "@/components/locale-select/locale-select.component.vue";
import i18n, { defaultLocale } from "@/i18n/i18n";

@Options({
  components: {
    "locale-select": LocaleSelectComponent,
  },
})
export default class IndexComponent extends Vue {
  public emailSent = false;
  public sendingEmail = false;
  public errorEmail = false;
  public errorMsg = "";
  public data: any = {
    name: "",
    email: "",
    message: "",
  };

  created(): void {
    const html = document.documentElement;
    html.setAttribute("lang", i18n?.global?.locale?.value || defaultLocale);
  }

  validEmail(email: string): boolean {
    //eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  sendEmail(): void {
    if (
      this.data.name !== "" &&
      this.data.email !== "" &&
      this.validEmail(this.data.email)
    ) {
      console.log("enviando mensaje...");
      this.sendingEmail = true;

      emailjs
        .send(
          EMAIL_CREDENTIALS.service_id,
          EMAIL_CREDENTIALS.template_id,
          {
            from: this.data.name,
            email: this.data.email,
            message: this.data.message,
          },
          EMAIL_CREDENTIALS.user_id
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.status, result.text);
            this.sendingEmail = false;
            this.emailSent = true;
          },
          (error) => {
            console.log("FAILED...", error);
            this.sendingEmail = false;
          }
        );
    } else {
      this.errorEmail = true;
      this.sendingEmail = false;

      if (this.data.name === "") {
        this.errorMsg = "error.required_name";
      } else if (this.data.email === "") {
        this.errorMsg = "error.required_email";
      } else if (!this.validEmail(this.data.email)) {
        this.errorMsg = "error.invalid_email";
      }

      setTimeout(() => {
        this.errorEmail = false;
        this.errorMsg = "";
      }, 10000);
    }
  }
}
