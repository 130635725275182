<footer class="footer flex justify-center w-full pt-10 pb-8">
	<div class="container relative">
		<div class="w-full grid-cols-1 grid md:grid-cols-3 gap-4 p-4 md:p-0">
			<div class="footer-item">
				<div class="logo mt-2">
					<a href="/" class="flex">
						<img class="max-h-10" src="/assets/logo.svg" alt="logo">
					</a>
				</div>

				<p class="text-left mt-6">{{ $t('footer.slogan') }}</p>
			</div>
			<div class="footer-item">
				<h3 class="mb-3 font-semibold text-xl">{{ $t('footer.company') }}</h3>

				<ul class="list flex flex-col">
					<li class="font-medium pb-2">
						<a class="clickable" v-scroll-to="'#home'">
							{{ $t('footer.home') }}
						</a>
					</li>
					<li class="font-medium pb-2">
						<a class="clickable" v-scroll-to="'#about'">
							{{ $t('footer.about_us') }}
						</a>
					</li>
					<li class="font-medium pb-2">
						<a class="clickable" v-scroll-to="'#services'">
							{{ $t('footer.services') }}
						</a>
					</li>
				</ul>
			</div>
			<div class="footer-item">
				<h3 class="mb-3 font-semibold text-xl">{{ $t('footer.address') }}</h3>

				<ul class="list flex flex-col">
					<li class="font-medium pb-2">
						<div class="flex flex-row">
							<i class="mr-2">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
									stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
										d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
										d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
								</svg>
							</i>
							Del Valle 932, Ciudad Empresarial, Santiago, Chile
						</div>
					</li>
					<li class="font-medium pb-2">
						<div class="flex flex-row">
							<i class="mr-2">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
									stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
										d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
								</svg>
							</i>
							{{ $t('footer.email') }}: hello@teknivox.com
						</div>
					</li>
					<li class="font-medium pb-2">
						<div class="flex flex-row">
							<i class="mr-2">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
									stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
										d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
								</svg>
							</i>
							{{ $t('footer.phone') }}: + (321) 984 754
						</div>
					</li>
				</ul>
			</div>
			<div class="footer-item md:col-span-3 col-span-1 mt-12">
				<div class="copyright-area pt-10 text-center">
					<p class="font-normal">{{ $t('footer.copyright') }}</p>
				</div>
			</div>

			<img src="/assets/map.webp" alt="map" class="map">
		</div>
	</div>
</footer>