import { createStore } from "vuex";

const store = createStore({
  state: {
    sideBar: false,
  },
  getters: {
    sideBar(state) {
      return state.sideBar;
    },
  },
  mutations: {
    toggleSideBar(state) {
      state.sideBar = !state.sideBar;
    },
  },
  actions: {
    toggleSideBar: ({ commit }) => {
      commit("toggleSideBar", false);
    },
  },
});

export default store;