<div class="locale-select">

  <div class="dropdown inline-block relative">
    <button class="font-semibold py-2 px-4 rounded-l shadow-lg inline-flex items-center">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129" />
			</svg>

      <span class="lang mr-1 capitalize hidden">{{ current }}</span>

      <svg class="icon fill-current h-5 w-5 hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
    </button>
    <ul class="dropdown-menu absolute hidden text-gray-700 pt-1 w-full">
			<template v-for="locale in locales">
      	<li class="w-full text-center clickable" v-if="locale !== current">
					<a class="rounded-t py-2 px-4 block whitespace-no-wrap font-semibold capitalize" @click="changeLocale(locale)">{{ locale }}</a>
				</li>
			</template>
    </ul>
  </div>

</div>