<header class="header flex justify-center" :class="{ fixed: fixedMenu }">
	<div class="container">
		<nav class="navbar flex justify-between md:justify-start items-center my-3">
			<a href="/" class="navbar-brand flex">
				<img class="max-h-8 w-full" src="/assets/logo.svg" alt="logo">
			</a>

			<div class="nav-toggle">
				<sidebar-toggle />
			</div>

			<div class="collapse navbar-collapse">
				<ul class="navbar-nav nav flex flex-row">
					<li class="nav-item">
						<a class="nav-link uppercase" :class="{ active: currentSection === 'home' }" v-scroll-to="'#home'">{{ $t('header.home') }}</a>
					</li>
					<li class="nav-item">
						<a class="nav-link uppercase" :class="{ active: currentSection === 'about' }" v-scroll-to="'#about'">{{ $t('header.about_us') }}</a>
					</li>
					<li class="nav-item">
						<a class="nav-link uppercase" :class="{ active: currentSection === 'services' }" v-scroll-to="'#services'">{{ $t('header.services') }}</a>
					</li>
					<li class="nav-item">
						<a class="nav-link uppercase" :class="{ active: currentSection === 'features' }" v-scroll-to="'#features'">{{ $t('header.features') }}</a>
					</li>
					<li class="nav-item">
						<a class="nav-link uppercase nav-buttom font-semibold" v-scroll-to="'#contact'">{{ $t('contact_us') }}</a>
					</li>
				</ul>
			</div>
		</nav>
	</div>

	<sidebar>
		<ul class="navbar-nav nav flex flex-col mt-14">
			<li class="nav-item">
				<a class="nav-link uppercase font-semibold" :class="{ active: currentSection === 'home' }" @click="scrollTo('#home')">{{ $t('header.home') }}</a>
			</li>
			<li class="nav-item">
				<a class="nav-link uppercase font-semibold" :class="{ active: currentSection === 'about' }" @click="scrollTo('#about')">{{ $t('header.about_us') }}</a>
			</li>
			<li class="nav-item">
				<a class="nav-link uppercase font-semibold" :class="{ active: currentSection === 'services' }" @click="scrollTo('#services')">{{ $t('header.services') }}</a>
			</li>
			<li class="nav-item">
				<a class="nav-link uppercase font-semibold" :class="{ active: currentSection === 'features' }" @click="scrollTo('#features')">{{ $t('header.features') }}</a>
			</li>
			<li class="nav-item">
				<a class="nav-link uppercase font-semibold" :class="{ active: currentSection === 'contact' }" @click="scrollTo('#contact')">{{ $t('contact_us') }}</a>
			</li>
		</ul>
	</sidebar>
</header>